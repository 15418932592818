/* eslint-disable no-unused-vars */
import { useState, useMemo } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import { Collapse } from "@mui/material";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import sidemenuConfig from "../../configs/sidemenu.config";
import userDetailsAtom from "../../atoms/user_details.atoms";
import { useEffect } from "react";

const listStyle = {
  fontSize: "inherit",
  color: (theme) => "#fff",
  "&:hover, &:focus": {
    "& svg": { opacity: open ? 1 : 0 },
    backgroundColor: "#02081570",
    color: (theme) => "#fff",
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
  },
  "&.Mui-selected": {
    backgroundColor: (theme) => theme.palette.secondary.main,
    color: (theme) => theme.palette.primary.main,
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
    "&:hover": {
      backgroundColor: (theme) => theme.palette.secondary.main,
      color: (theme) => theme.palette.primary.main,
      borderTopLeftRadius: "10px",
      borderBottomLeftRadius: "10px",
    },
  },
};

const childrenListStyle = {
  fontSize: "inherit",
  color: (theme) => "#fff",
  "&:hover, &:focus": {
    "& svg": { opacity: open ? 1 : 0 },
    backgroundColor: "#02081570",
    color: (theme) => "#fff",
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
  },
  "&.Mui-selected": {
    backgroundColor: "#fff",
    color: (theme) => theme.palette.primary.main,
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
    "&:hover": {
      backgroundColor: "#fff",
      color: (theme) => theme.palette.primary.main,
      borderTopLeftRadius: "10px",
      borderBottomLeftRadius: "10px",
    },
  },
};

// eslint-disable-next-line react/prop-types
export default function Sidemenu({ drawerWidth }) {
  const location = useLocation();
  const userDetails = useRecoilValue(userDetailsAtom);
  const [openCollapse, setOpenCollapase] = useState();
  const sideMenu = useMemo(
    () =>
      userDetails.usertype === "employer"
        ? sidemenuConfig.employerSideMenuConfig
        : sidemenuConfig.userSideMenuConfig,
    [userDetails]
  );

  const handleCollapse = (path) => {
    if (!openCollapse) setOpenCollapase(path);
    else setOpenCollapase();
  };

  useEffect(() => {
    if (sideMenu) {
      findActiveMenu(sideMenu);
    }
  }, [sideMenu, location?.pathname]);

  const findActiveMenu = (list, parentpath) => {
    list.forEach((element) => {
      if (!element.children && element.path === location.pathname) {
        if (parentpath) setOpenCollapase(parentpath);
        else setOpenCollapase();
        return;
      } else if (element?.children) {
        findActiveMenu(element.children, element?.path);
      } else return;
    });
  };

  const childrenItem = (menu, parentpath) => {
    return (
      <Collapse
        in={parentpath === openCollapse}
        timeout="auto"
        unmountOnExit
        sx={{ pl: "3.4rem", mt: 1 }}
      >
        {menu.map((dataElm) => (
          <ListItem
            key={dataElm.path}
            to={dataElm.path}
            component={!dataElm.children ? RouterLink : "li"}
            disablePadding
            sx={{ mb: 1 }}
          >
            <ListItemButton
              selected={dataElm.path === location.pathname}
              sx={childrenListStyle}
            >
              {/* <ListItemIcon>
              <dataElm.icon />
            </ListItemIcon> */}
              <ListItemText primary={dataElm.title} />
            </ListItemButton>
          </ListItem>
        ))}
      </Collapse>
    );
  };

  const listItem = (menu) => {
    return (
      <ListItem
        component={!menu.children ? RouterLink : "li"}
        to={menu.path}
        key={menu.title}
        disablePadding
        sx={{ display: "block", mb: 1 }}
      >
        <ListItemButton
          selected={
            menu.path === location.pathname ||
            (menu.children && openCollapse && openCollapse === menu?.path)
          }
          sx={listStyle}
          onClick={() => handleCollapse(menu.path)}
        >
          <ListItemIcon sx={{ color: "inherit" }}>
            <menu.icon sx={{ color: "inherit" }} />
          </ListItemIcon>
          <ListItemText primary={menu.title} sx={{ fontSize: "inherit" }} />
          {menu.children &&
            (menu.path === openCollapse ? <ExpandLess /> : <ExpandMore />)}
        </ListItemButton>
        {menu.children && childrenItem(menu.children, menu.path)}
      </ListItem>
    );
  };

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        background: "linear-gradient(111.42deg, #1940CC 0%, #21E09B 100%)",
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          boxSizing: "border-box",
          background: "linear-gradient(111.42deg, #1940CC 0%, #21E09B 100%)",
          color: "#fff",
        },
      }}
    >
      <Toolbar />
      <Box
        sx={{
          overflow: "auto",
          color: "#fff",
          marginLeft: "15px",
          fontSize: "0.8rem",
          mt: 5,
        }}
      >
        <List>{sideMenu.map((menu) => listItem(menu))}</List>
      </Box>
    </Drawer>
  );
}

Sidemenu.defaultProps = {
  drawerWidth: 0,
};
