import { atom } from "recoil";
import userDetails from "../constants/user_details.constants";

const localStorageEffect =
  (key) =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key);
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue, _, isReset) => {
      isReset
        ? localStorage.removeItem(key)
        : localStorage.setItem(key, JSON.stringify(newValue));
    });
  };

const userDetailsAtom = atom({
  key: userDetails.userDetails, // unique ID (with respect to other atoms/selectors)
  default: {}, // default value (aka initial value)
  effects: [localStorageEffect("userDetails")],
});

export default userDetailsAtom;
