import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1940CC",
    },
    secondary: {
      main: "#fff",
    },
  },
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          fontSize: "1rem",
          background: "linear-gradient(111.42deg, #1940CC 0%, #21E09B 100%);",
          boxShadow: "none",
          borderRadius: 8,
        },
        outlined: {
          background: "transparent",
        },
      },
    },
  },
});

export default theme;
