/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
import { useEffect, useState } from "react";

export default function Authwrapper({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState();
  useEffect(() => {
    if (!isAuthenticated && process.env.NODE_ENV !== "development") {
      const username = process.env.REACT_APP_USERNAME;
      if (username === process.env.REACT_APP_USERNAME) {
        setIsAuthenticated(true);
      }
    }
  }, []);
  return !isAuthenticated && process.env.NODE_ENV !== "development" ? (
    <div>authenticating</div>
  ) : (
    children
  );
}
