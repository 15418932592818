import React from "react";
import { RouterProvider } from "react-router-dom";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
//components
import useProtectedRoutes from "./configs/routes.configs";
import theme from "./themes";
import Authwrapper from "./components/auth_wrapper";

function App() {
  const { getRoutes } = useProtectedRoutes();
  return (
    <div>
      <ThemeProvider theme={theme}>
        <Authwrapper>
          <RouterProvider router={getRoutes()} />
        </Authwrapper>
      </ThemeProvider>
    </div>
  );
}

export default App;
