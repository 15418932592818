/* eslint-disable react/prop-types */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PageProgress from "./page_progress";

// eslint-disable-next-line no-undef
const runtimeConfig = require("../constants/runtime.constants");

export default function AuthProvider({ children }) {
  const navigate = useNavigate();
  const [proceed, setProceed] = useState(false);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    if (runtimeConfig.accessToken || localStorage.getItem("accessToken")) {
      runtimeConfig.accessToken = localStorage.getItem("accessToken");
      setTimeout(() => {
        setProceed(true);
        setLoader(false);
      }, 500);
    } else navigate("/login");
  }, []);
  if (proceed && !loader) return children;
  else return <PageProgress />;
}
