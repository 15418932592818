import { Box, CircularProgress } from "@mui/material";
import { useRecoilValue } from "recoil";
import pageProgressAtom from "../atoms/page_progress.atoms";

export default function PageProgress() {
  const pageProgress = useRecoilValue(pageProgressAtom);

  return pageProgress ? (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        position: "fixed",
        width: "100%",
        backgroundColor: "#1c212d8f",
        zIndex: 1201,
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    ""
  );
}
