import ContactMailIcon from "@mui/icons-material/ContactMail";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import VideocamIcon from "@mui/icons-material/Videocam";
import ArticleIcon from "@mui/icons-material/Article";
import UploadIcon from "@mui/icons-material/Upload";
import Person2Icon from "@mui/icons-material/Person2";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';


const userSideMenuConfig = [
  {
    title: "Registration",
    icon: ContactMailIcon,
    path: "/user_register/registration",
    type: "user",
  },
  {
    title: "Video Preparation",
    icon: VideocamIcon,
    path: "/user_register/video_preparation",
    type: "user",
  },
  {
    title: "CV Preparation",
    icon: StickyNote2Icon,
    path: "/user_register/cv_preparation",
    type: "user",
  },
  {
    title: "Personality Test",
    icon: ArticleIcon,
    path: "/user_register/personality_test",
    type: "user",
  },
  {
    title: "Upload Documents",
    icon: UploadIcon,
    path: "/user_register/upload_documents",
    type: "user",
  },
  {
    title: "Profile Preview",
    icon: Person2Icon,
    path: "/user_register/profile_preview",
    type: "user",
  },
];

const employerSideMenuConfig = [
  {
    title: "Job Listing",
    icon: BorderColorIcon,
    path: "job-listing",
    children: [
      {
        title: "Add New Job",
        path: "/employer/new-job",
      },
      {
        title: "Job Management",
        path: "/employer/job-management",
      },
    ],
  },
  {
    title: "Interviews",
    icon: PersonOutlineIcon,
    path: "/employer/interviews",
  },
  {
    title: "Users",
    icon: AccountCircleIcon,
    path: "/employer/users",
  },
];

export default { userSideMenuConfig, employerSideMenuConfig };
