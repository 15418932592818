import * as React from "react";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import { Outlet } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";

//components
import Sidemenu from "./sidemenu.layouts";
import Authwrapper from "../../components/auth_provider";
import PageProgress from "../../components/page_progress";
import { IconButton, Tooltip } from "@mui/material";

const drawerWidth = 302;

export default function ClippedDrawer() {
  return (
    <Authwrapper>
      <PageProgress />
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backgroundColor: "transparent",
            boxShadow: "none",
          }}
        >
          <Toolbar>
            <img src="/logo.png" alt="logo" />
            <Box sx={{ ml: 5 }}>
              <Tooltip title="Sign Out">
                <IconButton
                  variant="contained"
                  onClick={() => {
                    localStorage.removeItem("accessToken");
                    window.location.href = "/login";
                  }}
                >
                  <LogoutIcon sx={{ color: "#fff" }} />
                </IconButton>
              </Tooltip>
            </Box>
          </Toolbar>
        </AppBar>
        <Sidemenu drawerWidth={drawerWidth} />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Toolbar />
          <React.Suspense fallback="loading...">
            <Outlet />
          </React.Suspense>
        </Box>
      </Box>
    </Authwrapper>
  );
}
