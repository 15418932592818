import { lazy } from "react";
import { createBrowserRouter, Navigate, Outlet } from "react-router-dom";
import Mainlayout from "../layouts/main.layouts";
// pages
const LoginLayout = lazy(() => import("../layouts/login.layouts"));
const Login = lazy(() => import("../components/auth"));
// const Record = lazy(() => import("../components/recorder"));
const Registration = lazy(() => import("../views/registration/register"));
const Videopreparation = lazy(() =>
  import("../views/registration/video_preparation")
);
const PersonalityTest = lazy(() =>
  import("../views/registration/personality_test")
);
const CvPreparation = lazy(() =>
  import("../views/registration/cv_preparation")
);
const UploadDOcuments = lazy(() =>
  import("../views/registration/upload_documents")
);
const ProfilePreview = lazy(() => import("../views/registration/profile_view"));

const EmployerLogin = lazy(() => import("../views/employer/auth/login"));

const EmployerNewJob = lazy(() =>
  import("../views/employer/job_listing/new_job")
);

const EmployerJobManagement = lazy(() =>
  import("../views/employer/job_listing/job_management")
);
const EmployerInterviews = lazy(() => import("../views/employer/interviews"));
const EmployerUsers = lazy(() => import("../views/employer/users"));

const AddApplicant = lazy(() => import("../views/public/add_applicant"));

const commonRoutes = [
  {
    path: "/login",
    element: <LoginLayout />,
    // loader: <div>loading</div>,
    children: [
      {
        path: "employer",
        element: <EmployerLogin />,
        // loader: <div>loading</div>,
      },
      {
        path: "",
        element: <Login />,
        // loader: <div>loading</div>,
      },
    ],
  },
  {
    path: "public",
    element: (
      <div style={{ width: "65%", margin: "auto" }}>
        <Outlet />
      </div>
    ),
    children: [
      {
        path: "add_applicant",
        element: <AddApplicant />,
      },
    ],
  },
];

const employerRoutes = [
  {
    path: "/employer",
    element: <Mainlayout />,
    // loader: <div>loading</div>,
    children: [
      {
        path: "new-job",
        element: <EmployerNewJob />,
      },
      {
        path: "job-management",
        element: <EmployerJobManagement />,
      },
      {
        path: "interviews",
        element: <EmployerInterviews />,
      },
      {
        path: "users",
        element: <EmployerUsers />,
      },
    ],
  },
];

const userRoutes = [
  {
    path: "user_register",
    element: <Mainlayout />,
    // loader: <div>loading</div>,
    children: [
      {
        path: "registration",
        element: <Registration />,
        // loader: <div>loading</div>,
      },
      {
        path: "video_preparation",
        element: <Videopreparation />,
        // loader: <div>loading</div>,
      },
      {
        path: "personality_test",
        element: <PersonalityTest />,
      },
      {
        path: "cv_preparation",
        element: <CvPreparation />,
      },
      {
        path: "upload_documents",
        element: <UploadDOcuments />,
      },
      {
        path: "profile_preview",
        element: <ProfilePreview />,
      },
    ],
  },
  {
    path: "*",
    element: <Navigate to="/login" replace={true} />,
    // loader: <div>loading</div>,
  },
];

import { useRecoilValue } from "recoil";
import userDetailsAtom from "../atoms/user_details.atoms";

function useProtectedRoutes() {
  const userDetails = useRecoilValue(userDetailsAtom);

  const getRoutes = () => {
    const finalRoutes = [
      ...commonRoutes,
      ...(userDetails?.usertype === "employer" ? employerRoutes : userRoutes),
    ];
    return createBrowserRouter(finalRoutes);
  };

  return { getRoutes };
}

export default useProtectedRoutes;
